import React from "react";
import "./navbar.css";
import { useState, useRef } from "react";

function NavBar () {

    const [isDrop, setIsDrop] = useState(false)

    return(
        <div className='nav'>
            
            <div className={isDrop ? 'gradient active' : 'gradient'}></div>

            <div className={isDrop ? 'navList active' : 'navList'}>
                    <a href="/" className="btn">INTRO</a>
                    <a href="gallery" className="btn">GALLERY</a>
                    <div className="btn2" style={{color: "gray"}}>
                        BLOG
                        <div className="cover">coming soon</div>
                    </div>
                    <div className="btn2" style={{color: "gray"}}>
                        PROJECTS
                        <div className="cover">coming soon</div>
                    </div>
            </div>

            <div className='menu-icon' onClick={() =>  setIsDrop(!isDrop)}>
                <div className= {isDrop ? 'menu-btn-burger active' : 'menu-btn-burger'}></div>
            </div>

            <div id="padding"></div>
        </div>
    )
}

export default NavBar
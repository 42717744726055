
import React from "react";

import "./GlitchText.scss";

function Glitch_text(text) {

    if (!text) { return ( <div /> ) }
    return (
        <div className="title-container">
            <div className="glitch" data-text="JOBS">{text.text}</div>
            <div className="glow" >{text.text}</div>
        </div>
    );
}
export default Glitch_text
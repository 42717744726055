import React, { useEffect, useState, useRef } from "react"
import { ScrollTrigger } from "gsap/all";

import gsap from "gsap";
import Scrollbar from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';

import "./CSS/intro.css"
import "./CSS/overlay.css"
import "./CSS/landing.css"
import "./CSS/aboutme.css"
import "./CSS/indicators.css"
import "./CSS/projects.css"
import "./CSS/jobs.css"
import "./CSS/skills.css"
import "./CSS/footer.css"
import "../../components/AnimatedText/smoke_text.css";

import NavBar from "../../components/navbar/NavBar";
import CyberpunkScene from "./CyberpunkScene/CyberpunkScene"
import SkillBar from "./SkillBar/SkillBar";
import GlitchText from "../../components/AnimatedText/GlitchText";
import GLTFspinner from "../../components/GLTFspinner/GLTFspinner";
import Stars2 from "./Stars/Stars2";

//swiper test
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import { Autoplay, EffectCards } from "swiper/modules";

gsap.registerPlugin(ScrollTrigger);

function Intro() {

    //PRELOADING
    let loadingScreenLeftRef = useRef(null);
    let loadingScreenRightRef = useRef(null);
    //const [isLoadingComplete, setIsLoadingComplete] = useState(false);

    //REVEAL REFS
    let revealRefs = useRef([]);
    revealRefs.current = [];
    const addToRefs = (el) => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    }

    //INITIALIZE GSAP REVEAL
    useEffect(() => {
        gsap.ticker.lagSmoothing();
        gsap.to(loadingScreenLeftRef.current, { width: 0, duration: 1, ease: 'power1.out', onComplete: function () { BuildTextReveal() } }).delay(2);
        gsap.to(loadingScreenRightRef.current, { width: 0, duration: 1, ease: 'power1.out' }).delay(2);

        function BuildTextReveal() {
            revealRefs.current.forEach((el, index) => {
                gsap.fromTo(el,
                    { opacity: 0, y: -20, ease: 'power3.inOut' },
                    {
                        opacity: 1, y: 0, ease: 'power3.inOut', duration: 1,
                        scrollTrigger: { trigger: el, id: `section-${index + 1}`, start: 'top bottom', toggleActions: 'play none none none', markers: false }
                    }
                )
            })
        }
    }, [])

    //scroll
    const myScrollbar = useRef(Scrollbar);
    //navbar
    const [displayName, setDisplayName] = useState(false);
    //moon
    const atmosphereRef = useRef()
    const moonRef = useRef()
    const [moonSize, setMoonSize] = useState("100%");
    //clouds
    const SceneImgRef = useRef()
    const CliffImgRef = useRef()
    const CloudImgRef = useRef()
    //indicators
    const fixedElem = useRef(null);
    const fixedNav = useRef(null);
    const fixedOverlay = useRef(null);
    const startSectionRef = useRef(null);
    const projectSectionRef = useRef(null);
    const jobSectionRef = useRef(null);
    const skillsSectionRef = useRef(null);

    const [isIndicator1, setIsIndicator1] = useState(true);
    const [isIndicator2, setIsIndicator2] = useState(false);
    const [isIndicator3, setIsIndicator3] = useState(false);
    const [isIndicator4, setIsIndicator4] = useState(false);

    const IndicatorArray = [setIsIndicator1, setIsIndicator2, setIsIndicator3, setIsIndicator4];

    function toggleIndicator(setIsIndicator, scrollRef, shoudMove) {
        IndicatorArray.forEach((child) => {
            child(false);
        });
        setIsIndicator(true);
        if (shoudMove) { myScrollbar.current.scrollTo(0, scrollRef + 1, 1000); }
    }

    //SCROLL INITIALIZATION / LISTENING
    useEffect(() => {
        Scrollbar.use(OverscrollPlugin);
        var bodyScrollBar = Scrollbar.init(document.body, {
            damping: 0.1,
            plugins: { overscroll: {} | false },
        });
        myScrollbar.current = bodyScrollBar;
        // fixed element
        bodyScrollBar.addListener(function (status) {
            fixedElem.current.style.top = status.offset.y + 'px';
            fixedNav.current.style.top = status.offset.y + 'px';
            fixedOverlay.current.style.top = status.offset.y + 'px';

            if (!startSectionRef || !projectSectionRef || !jobSectionRef || !skillsSectionRef) { return };
            if (status.offset.y > startSectionRef.current.offsetTop && status.offset.y < projectSectionRef.current.offsetTop) {
                toggleIndicator(setIsIndicator1, startSectionRef.current.offsetTop, false);
            } else if (status.offset.y > projectSectionRef.current.offsetTop && status.offset.y < jobSectionRef.current.offsetTop) {
                toggleIndicator(setIsIndicator2, projectSectionRef.current.offsetTop, false);
            } else if (status.offset.y > jobSectionRef.current.offsetTop && status.offset.y < skillsSectionRef.current.offsetTop) {
                toggleIndicator(setIsIndicator3, jobSectionRef.current.offsetTop, false);
            } else if (status.offset.y > skillsSectionRef.current.offsetTop) {
                toggleIndicator(setIsIndicator4, skillsSectionRef.current.offsetTop, false);
            }

            if (myScrollbar.current.scrollTop > window.innerHeight) { setDisplayName(true) } else { setDisplayName(false) };

            //moonSize
            //setMoonSize(String(100 - (myScrollbar.current.scrollTop * .04) + '%'));

            // PARALLAX
            moonRef.current.style.top = myScrollbar.current.scrollTop * 0.35 + "px";
            atmosphereRef.current.style.top = -myScrollbar.current.scrollTop * 0.25 + (window.innerHeight * .9) + "px";

            SceneImgRef.current.style.top = -myScrollbar.current.scrollTop * 0.05 + (window.innerHeight * .1) + "px";
            CliffImgRef.current.style.top = -myScrollbar.current.scrollTop * 0.1 + (window.innerHeight * .15) + "px";
        })
        //
        bodyScrollBar.setPosition(0, 0);
        bodyScrollBar.track.xAxis.element.remove();
        ScrollTrigger.scrollerProxy("body", {
            scrollTop() {
                return bodyScrollBar.scrollTop;
            }
        });
    }, [])

    //MOUSE EVENT LISTENER
    useEffect(() => {
        window.addEventListener("mousemove", parallax);
        function parallax(e) {
            var x = (e.pageX - window.innerWidth / 2) * 7 / 100;
            var y = ((e.pageY - 500) - window.innerHeight / 2) * 4 / 100;
            SceneImgRef.current.style.backgroundPositionX = `calc(50% - ${x}px)`;
            SceneImgRef.current.style.backgroundPositionY = `calc(15% - ${y}px)`;

            x = (e.pageX - window.innerWidth / 2) * 4 / 100;
            y = ((e.pageY - 500) - window.innerHeight / 2) * 2 / 100;
            CliffImgRef.current.style.backgroundPositionX = `calc(50% - ${x}px)`;
            CliffImgRef.current.style.backgroundPositionY = `calc(15% - ${y}px)`;

            x = (e.pageX - window.innerWidth / 2) * 2 / 100;
            CloudImgRef.current.style.backgroundPositionX = `calc(50% - ${x}px)`;
        }
        return () => window.removeEventListener("mousemove", parallax);
    }, []);

    //photo
    const [selectedImage, setSelectedImage] = useState(0);
    const [isOverlay, setIsOverlay] = useState(false);
    const [isVideo, setIsVideo] = useState(false);
    function ToggleOverlay(num, video) {
        setIsOverlay(!isOverlay); 
        setIsVideo(video);
        if(num !== null) {setSelectedImage(num)};
    };

    const [imageLibrary, setImageLibrary] = useState([
        //Boulder
        "https://www.youtube.com/embed/esX3Hl4WQ3c?controls=0&rel=0&modestbranding=1&mute=1&autoplay=1&loop=1&playlist=esX3Hl4WQ3c",
        "https://www.youtube.com/embed/-Nd4HeOlEB8?controls=0&rel=0&modestbranding=1&mute=1&autoplay=1&loop=1&playlist=-Nd4HeOlEB8",
        "https://www.youtube.com/embed/Xxy8wcCSIwY?controls=0&rel=0&modestbranding=1&mute=1&autoplay=1&loop=1&playlist=Xxy8wcCSIwY",
        "./projects/Boulder/arena.jpg",
        "./projects/Boulder/whiteboard.jpg",
        //IntoOrbit
        "https://www.youtube.com/embed/mqnAhPLXmGE?controls=0&rel=0&modestbranding=1&mute=1&autoplay=1&loop=1&playlist=mqnAhPLXmGE",
        "./projects/IntoOrbit/IntoOrbit_Screenshot_1.png",
        "./projects/IntoOrbit/IntoOrbit_Screenshot_2.png",
        "./projects/IntoOrbit/IntoOrbit_Screenshot_3.png",
        "./projects/IntoOrbit/IntoOrbit_Screenshot_4.png",
        "./projects/IntoOrbit/one_page_Prototype_1.png",
        //Invasion of Adventures
        "./projects/InvasionOfAdventures/DungeonGame.png",
        "./projects/InvasionOfAdventures/UnitSelection.gif",
        //ditd
        "./projects/DownInTheDice/CatGame.png",
        "./projects/DownInTheDice/attack1.png",
        "./projects/DownInTheDice/dungeon1.png",
        "./projects/DownInTheDice/dungeon4.png",
        //capstone
        "./projects/Capstone/gp2.png",
        "./projects/Capstone/gp3.png",
        "./projects/Capstone/gen1.png",
        "./projects/Capstone/gen2.png",
        "./projects/Capstone/gen3.png",
        "./projects/Capstone/gen4.png",
        "./projects/Capstone/gen5.png",
        "./projects/Capstone/gen6.png",
        "./projects/Capstone/dead2.png",
        //boids
        "./projects/Boids/LASERS!.mp4",
        "./projects/Boids/HOARD!.mp4",
    ]);

    return (
        <div id='intro' >

            {/*_____________________INTRO SCREEN_____________________*/}
            <div className="loading-screen-left" ref={loadingScreenLeftRef}></div>
            <div className="loading-screen-right" ref={loadingScreenRightRef}></div>

            {/*_____________________NAVBAR.CSS_____________________*/}
            <div className={isOverlay ? "nav-area active" : "nav-area"} id="nav-area" ref={fixedNav}>
                <NavBar /> 
                <div className={displayName ? "name active" : "name"} onClick={() => toggleIndicator(setIsIndicator1, startSectionRef.current.offsetTop, true)}>CHAI</div> 
            </div>

            {/*_____________________OVERLAY.CSS_____________________*/}
            <div id="backdrop-fixer" ref={fixedOverlay}>
                <div className={isOverlay ? "backdrop active" : "backdrop"}  onClick={() => ToggleOverlay(null)}>
                    {isVideo ? 
                    <video src={imageLibrary[selectedImage]} alt="" autoPlay loop muted/>
                    :
                    <img src={imageLibrary[selectedImage]} alt="enlarged pic" />
                    }
                    
                </div>
            </div>

            {/*_____________________INDICATORS.CSS_____________________*/}
            <div id="indicator-area" ref={fixedElem}>
                <div className={isIndicator1 ? "intro-indicator active" : "intro-indicator"} onClick={() => toggleIndicator(setIsIndicator1, startSectionRef.current.offsetTop, true)}></div>
                <div className={isIndicator2 ? "projects-indicator active" : "projects-indicator"} onClick={() => toggleIndicator(setIsIndicator2, projectSectionRef.current.offsetTop, true)}></div>
                <div className={isIndicator3 ? "jobs-indicator active" : "jobs-indicator"} onClick={() => toggleIndicator(setIsIndicator3, jobSectionRef.current.offsetTop, true)}></div>
                <div className={isIndicator4 ? "skills-indicator active" : "skills-indicator"} onClick={() => toggleIndicator(setIsIndicator4, skillsSectionRef.current.offsetTop, true)}></div>
            </div>

            {/*_____________________LANDING.CSS_____________________*/}

            <div id="stars-background"><Stars2 /></div>
            <div id="landing-area" ref={startSectionRef}>
                <div id="space-text-area">
                    <div id="space-text-container">
                        <div id="name-text" ref={addToRefs}><span>CHAI</span><br /><span>LIN</span></div>
                        <div id="titles-text" ref={addToRefs}><span>programmer</span><br /><span>game designer</span><br /><span>web developer?</span></div>
                    </div>
                </div>
                {/*<div id="moon-gltf" ref={moonRef}> <GLTFspinner data={[isIndicator1, "/polyplanet/polyplanet.gltf", 5]} /></div>*/}
                <div id="moon-gltf" ref={moonRef} style={{ width: moonSize, height: moonSize }}> <GLTFspinner data={[isIndicator1, "/polyplanet/polyplanet.glb", -1]} /></div>
            </div>

            {/*_____________________ABOUTME.CSS_____________________*/}
            <div id="atmosphere-transition" ref={atmosphereRef}></div>

            <div id="cloud-scene-area" ref={projectSectionRef}>
                <div id="cloud-scene">
                    <div id="scene-img" ref={SceneImgRef}></div>
                    <div id="cliff-img" ref={CliffImgRef}></div>
                    <div id="clouds-img" ref={CloudImgRef}></div>
                </div>

                <div id="projects-title-area" >
                    <div className="section-title" ref={addToRefs}>
                        ABOUT ME
                        {/*<ul><li>P</li><li>R</li><li>O</li><li>J</li><li>E</li><li>C</li><li>T</li><li>S</li></ul>*/}
                    </div>
                </div>
            </div>

            <div className="line-area" style={{ backgroundColor: "white", height: "8vh" }}><div className="line" style={{ backgroundColor: "gray" }}></div></div>

            <div id="aboutme-area">
                <div id="intro-container" ref={addToRefs}><h3>Hello! My name is Chai Lin. Thank you for taking the time to visit my portfolio!</h3></div>
                <div id="mission-container" ref={addToRefs}><h3>I am on a lifelong journey guided by big dreams. With a daring imagination, I aspire to push the boundaries of game design, introducing innovative concepts that redefine player engagement and crafting immersive worlds that captivate and resonate. My mission is to contribute to a world where innovation thrives, where the pursuit of excellence is relentless, and where every endeavor is infused with a spirit of limitless possibilities.</h3></div>
                <div id="current-container" ref={addToRefs}><h3>I graduated from Kent State University with a B.S. in Computer Science. Now, I am enrolled at the University of Utah, pursuing my Masters in Entertainment Arts and Engineering in the Engineering Track. Below you can find my portfolio highlighting some of my game development work.</h3></div>
            </div>

            <div className="line-area" style={{ backgroundColor: "white", height: "8vh" }}><div className="line" style={{ backgroundColor: "gray" }}></div></div>
            {/*<div style={{paddingLeft: '2em'}}> REMINDER HOW TO INDENT*/}
           

            {/*_____________________PROJECTS.CSS_____________________*/}
            <div className="cyberpunk_scene" ref={jobSectionRef}>
                <CyberpunkScene />
            </div>

            <div className="jobs-title-area" >
                {/*<div id="title-container"  ref={addToRefs}> <GlitchText text={"JOBS"} /> </div>*/}
                <div className="section-title" style={{color: "white"}} ref={addToRefs}> PROJECTS </div>
            </div>

            <div className="line-area" style={{ backgroundColor: "black", height: "8vh" }}><div className="line" style={{ backgroundColor: "gray" }}></div></div>

            <div className="project-area">
                <div className="project-container">
                    <div className="text-container">
                        <h1 className="project-title" ref={addToRefs} href="down-in-the-dice">BoulderBash</h1>
                        <h2 className="skills-titles" ref={addToRefs}>
                            <div className="tag-box" style={{ backgroundColor: "rgb(14, 17, 40)" }}>UE5</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(54, 54, 54)" }}>Blueprints</div>  
                            <div className="tag-box" style={{ backgroundColor: "rgb(47, 109, 181)" }}>Perforce</div> 
                            <div className="tag-box" style={{ background: "linear-gradient(90deg, rgba(190,0,0,1) 0%, rgba(0,0,0,1) 100%)" }}>University</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(58, 191, 192)" }}>Team</div> 
                        </h2>
                        <h3 className="project-text" ref={addToRefs}>
                            <ul style={{ paddingLeft: "35px" }}>
                                <li>...</li>
                            </ul>
                        </h3>
                    </div>
                    <Swiper effect={"cards"} grabCursor={true}  modules={[EffectCards]} className="mySwiper">
                        <SwiperSlide>
                            <div class="video-container"><iframe src={imageLibrary[0]} frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="resize-youtube-video"></iframe></div>
                            <div id="fill" onClick={() => ToggleOverlay(0, false)}></div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div class="video-container"><iframe src={imageLibrary[1]} frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                            <div id="fill" onClick={() => ToggleOverlay(1, false)}></div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div class="video-container"><iframe src={imageLibrary[2]} frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                            <div id="fill" onClick={() => ToggleOverlay(2, false)}></div>
                        </SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[3]} alt="" onClick={() => ToggleOverlay(3, false)}/></SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[4]} alt="" onClick={() => ToggleOverlay(4, false)}/></SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="line-area" style={{ backgroundColor: "black", height: "8vh" }}><div className="line" style={{ backgroundColor: "gray" }}></div></div>

            <div className="project-area">
                <div className="project-container">
                    <div className="text-container">
                        <div id="IntoOrbit-title" ref={addToRefs}></div>
                        <h2 className="skills-titles" ref={addToRefs}>
                            <div className="tag-box" style={{ backgroundColor: "rgb(53, 147, 255)" }}>Unity</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(129, 0, 146)" }}>C#</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(117, 142, 153)" }}>Asprite</div> 
                            <div className="tag-box" style={{ background: "linear-gradient(90deg, rgba(190,0,0,1) 0%, rgba(0,0,0,1) 100%)" }}>University</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(58, 191, 192)" }}>Team</div> 
                        </h2>
                        <h3 className="project-text" ref={addToRefs}>
                            <ul style={{ paddingLeft: "35px" }}>
                                <li>As the team’s Engineer, designed and developed a 64-bit Space Invaders-inspired game where the goal is to defend the planet as the last shield against an infinite onslaught. </li>
                                <li>Collaborated with a producer, artists, and a technical artist. </li>
                                <li>3-week timeframe utilizing Agile development.</li>
                                <li>Implemented all systems and UI while contributing to game design decisions and delegating tasks.</li>
                                <li>Iteratively refined systems based on feedback.</li>
                                <li><a href="https://fujichi.itch.io/intoorbit" target="_blank">itch.io</a></li>
                            </ul>
                        </h3>
                    </div>
                    <Swiper effect={"cards"} grabCursor={true}  modules={[EffectCards]} className="mySwiper" >
                        <SwiperSlide>
                            <div class="video-container"><iframe src={imageLibrary[5]} frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="resize-youtube-video"></iframe></div>
                            <div id="fill" onClick={() => ToggleOverlay(5, false)}></div>
                        </SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[6]} alt="" onClick={() => ToggleOverlay(6, false)}/></SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[7]} alt="" onClick={() => ToggleOverlay(7, false)}/></SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[8]} alt="" onClick={() => ToggleOverlay(8, false)}/></SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[9]} alt="" onClick={() => ToggleOverlay(9, false)}/></SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[10]} alt="" onClick={() => ToggleOverlay(10, false)}/></SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="line-area" style={{ backgroundColor: "black", height: "8vh" }}><div className="line" style={{ backgroundColor: "gray" }}></div></div>

            <div className="project-area">
                <div className="project-container">
                    <div className="text-container">
                        <h1 className="project-title" ref={addToRefs} href="down-in-the-dice">Invasion of Adventures</h1>
                        <h2 className="skills-titles" ref={addToRefs}>
                            <div className="tag-box" style={{ backgroundColor: "rgb(53, 147, 255)" }}>Unity</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(129, 0, 146)" }}>C#</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(117, 142, 153)" }}>Asprite</div> 
                            <div className="tag-box" style={{ background: "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)" }}>GameJam</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(82, 34, 240)" }}>Duo</div> 
                        </h2>
                        <h3 className="project-text" ref={addToRefs}>
                            <ul style={{ paddingLeft: "35px" }}>
                                <li>GMTK Game Jam 2023</li>
                                <li>23.1k participants & 6,767 submissions</li>
                                <li>Theme “Roles Reversed”</li>
                                <li>48 hours</li>
                                <li>16-bit, reverse dungeon</li>
                                <li>Economy system & Exploring AI</li>
                                <li><a href="https://fujichi.itch.io/invasion-of-adventurers" target="_blank">itch.io</a></li>
                            </ul>
                        </h3>
                    </div>
                    <Swiper effect={"cards"} grabCursor={true}  modules={[EffectCards]} className="mySwiper" >

                        <SwiperSlide><img src={imageLibrary[11]} alt="" onClick={() => ToggleOverlay(11, false)}/></SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[12]} alt="" onClick={() => ToggleOverlay(12, false)}/></SwiperSlide>
                        <SwiperSlide><video src={""} alt=""/></SwiperSlide>

                    </Swiper>
                </div>
            </div>

            <div className="line-area" style={{ backgroundColor: "black", height: "8vh" }}><div className="line" style={{ backgroundColor: "gray" }}></div></div>

            <div className="project-area">
                <div className="project-container">
                    <div className="text-container">
                        <h1 className="project-title" ref={addToRefs} href="down-in-the-dice">Down in the Dice</h1>
                        <h2 className="skills-titles" ref={addToRefs}>
                            <div className="tag-box" style={{ backgroundColor: "rgb(53, 147, 255)" }}>Unity</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(129, 0, 146)" }}>C#</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(117, 142, 153)" }}>Asprite</div> 
                            <div className="tag-box" style={{ background: "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)" }}>GameJam</div> 
                            <div className="tag-box" style={{ backgroundColor: "green" }}>Solo</div> 
                        </h2>
                        <h3 className="project-text" ref={addToRefs}>
                            <ul style={{ paddingLeft: "35px" }}>
                                <li>GMTK Game Jam 2022</li>
                                <li>22k participants & 6,121 submissions</li>
                                <li>Theme “Roll of the Dice"</li>
                                <li>48 hours</li>
                                <li>16-bit, rogue-like, dungeon crawler</li>
                                <li>Placed top 30% {"(1844/6121)"}.</li>
                                <li><a href="https://fujichi.itch.io/down-in-the-dice" target="_blank">itch.io</a></li>
                            </ul>
                        </h3>
                    </div>
                    <Swiper effect={"cards"} grabCursor={true}  modules={[EffectCards]} className="mySwiper">
                        <SwiperSlide><img src={imageLibrary[13]} alt="" onClick={() => ToggleOverlay(13, false)}/></SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[14]} alt="" onClick={() => ToggleOverlay(14, false)}/></SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[15]} alt="" onClick={() => ToggleOverlay(15, false)}/></SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[16]} alt="" onClick={() => ToggleOverlay(16, false)}/></SwiperSlide>
                        <SwiperSlide><video src={""} alt=""/></SwiperSlide>

                    </Swiper>
                </div>
            </div>

            <div className="line-area" style={{ backgroundColor: "black", height: "8vh" }}><div className="line" style={{ backgroundColor: "gray" }}></div></div>

            <div className="project-area">
                <div className="project-container">
                    <div className="text-container">
                        <h1 className="project-title" ref={addToRefs} href="down-in-the-dice">Capstone</h1>
                        <h2 className="skills-titles" ref={addToRefs}>
                        <div className="tag-box" style={{ backgroundColor: "rgb(53, 147, 255)" }}>Unity</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(129, 0, 146)" }}>C#</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(234, 118, 0)" }}>Blender</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(0, 30, 54)" }}>PSD</div> 
                            <div className="tag-box" style={{ background: "linear-gradient(90deg, rgba(4,30,66,1) 0%, rgba(239,171,0,1) 100%)" }}>University</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(58, 191, 192)" }}>Team</div> 
                        </h2>
                        <h3 className="project-text" ref={addToRefs}>
                            <ul style={{ paddingLeft: "35px" }}>
                                <li>Car survival game with corresponding website.</li>
                                <li>Team of 5 + stakeholder {" (game engines professor)"}</li>
                                <li>Team leader / Game Designer / Solo Unity Coder</li>
                                <li>Elicitation plan, Functional requirements, and Interviews with our stakeholder</li>
                                <li>Procedural World Generation</li>
                                <li>Online leaderboard</li>
                                <li><a href="https://github.com/epraeter/Capstone" target="_blank">github.com</a></li>
                            </ul>
                        </h3>
                    </div>

                    <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]} className="mySwiper">
                        <SwiperSlide><img src={imageLibrary[17]} alt="" onClick={() => ToggleOverlay(17, false)}/></SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[18]} alt="" onClick={() => ToggleOverlay(18, false)}/></SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[19]} alt="" onClick={() => ToggleOverlay(19, false)}/></SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[20]} alt="" onClick={() => ToggleOverlay(20, false)}/></SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[21]} alt="" onClick={() => ToggleOverlay(21, false)}/></SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[22]} alt="" onClick={() => ToggleOverlay(22, false)}/></SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[23]} alt="" onClick={() => ToggleOverlay(23, false)}/></SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[24]} alt="" onClick={() => ToggleOverlay(24, false)}/></SwiperSlide>
                        <SwiperSlide><img src={imageLibrary[25]} alt="" onClick={() => ToggleOverlay(25, false)}/></SwiperSlide>
                        <SwiperSlide><video src={""} alt=""/></SwiperSlide>

                    </Swiper>
                </div>
            </div>

            <div className="line-area" style={{ backgroundColor: "black", height: "8vh" }}><div className="line" style={{ backgroundColor: "gray" }}></div></div>

            <div className="project-area">
                <div className="project-container">
                    <div className="text-container">
                        <h1 className="project-title" ref={addToRefs} href="down-in-the-dice">2D Boid Simulation with ROBOTS!</h1>
                        <h2 className="skills-titles" ref={addToRefs}>
                            <div className="tag-box" style={{ backgroundColor: "rgb(53, 147, 255)" }}>Unity</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(108, 194, 74)" }}>ECS 0.51.0</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(129, 0, 146)" }}>C#</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(117, 142, 153)" }}>Asprite</div> 
                            <div className="tag-box" style={{ background: "linear-gradient(90deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)" }}>PersonProject</div> 
                            <div className="tag-box" style={{ backgroundColor: "green" }}>Solo</div> 
                        </h2>
                        <h3 className="project-text" ref={addToRefs}>
                            <ul style={{ paddingLeft: "35px" }}>
                                <li>Data oriented programming</li>
                                <li>simulates the flocking behavior of birds</li>
                                <li>rules: separation, alignment, and cohesion</li>
                                <li>goal seeking</li>
                                <li>movement, collision, and animation running in parallel</li>
                                <li>Custom sprite sheet animator</li>
                                <li>2 million entities simulating at once {" (30fps)"}</li>
                            </ul>
                        </h3>
                    </div>
                    <Swiper effect={"cards"} grabCursor={true}  modules={[EffectCards]} className="mySwiper" >
                        <SwiperSlide><video src={imageLibrary[26]} alt="" onClick={() => ToggleOverlay(21, true)} autoPlay loop muted/></SwiperSlide>
                        <SwiperSlide><video src={imageLibrary[27]} alt="" onClick={() => ToggleOverlay(22, true)} autoPlay loop muted/></SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="line-area" style={{ backgroundColor: "black", height: "8vh" }}><div className="line" style={{ backgroundColor: "gray" }}></div></div>

            <div className="project-area">
                <div className="project-container">
                    <div className="text-container">
                        <h1 className="project-title" ref={addToRefs} href="down-in-the-dice">This Website!</h1>
                        <h2 className="skills-titles" ref={addToRefs}>
                            <div className="tag-box" style={{ backgroundColor: "rgb(21, 227, 235)" }}>React</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(240, 219, 79)" }}>JavaScript</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(60, 153, 220)" }}>CSS</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(227, 76, 38)" }}>HTML</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(112, 114, 113)" }}>ThreeJS</div> 
                            <div className="tag-box" style={{ backgroundColor: "rgb(255, 166, 17)" }}>FireBase</div> 
                            <div className="tag-box" style={{ background: "linear-gradient(90deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)" }}>PersonProject</div> 
                            <div className="tag-box" style={{ backgroundColor: "green" }}>Solo</div> 
                        </h2>
                        <h3 className="project-text" ref={addToRefs}>
                            <ul style={{ paddingLeft: "35px" }}>
                                <li>Themed descent from the moon into Earth showcasing different game art styles: low poly, realism, pixel art, hand painted, and monochromatic.</li> 
                                <li>ThreeJS spinnable planet made in Blender.</li>     
                                <li>Parallax backgrounds with mouse movement.</li>   
                                <li>CSS sprite sheet animator and animated navigation bar.</li>  
                                <li>Photo gallery made with Firebase API calls, with dev tools to login, where you can upload and tag images converted to WebP format when necessary.</li>  
                                <li>Forever in progress</li> 
                            </ul>
                        </h3>
                    </div>
                    <div className="white-screen">
                    </div>



                </div>
            </div>

            <div className="line-area" style={{ backgroundColor: "black", height: "8vh" }}><div className="line" style={{ backgroundColor: "gray" }}></div></div>

            {/*_____________________SKILLS.CSS_____________________*/}
            <div className="cave-scene" ref={skillsSectionRef}>  </div>

            <div className="skill-title-area">
                <div className="title-container">
                    <div className="section-title" ref={addToRefs}>Skills</div>
                </div>
            </div>


            <div className="skills-area">
                <div className="skills-flexbox">
                    <div className="skills-container" ref={addToRefs}>
                        <h3 style={{fontWeight: "500"}}>Languages & Frameworks</h3>
                        <h3>
                            <ul style={{ paddingLeft: "22px" }}>
                                <li>C#, C++, C</li>
                                <li>JS, CSS, HTML</li>
                                <li>React</li>
                                <li>Python</li>
                                <li>Blueprints</li>
                            </ul>
                        </h3>
                    </div>
                    <div className="skills-container" ref={addToRefs}>
                        <h3 style={{fontWeight: "500"}}>Games</h3>
                        <h3>
                            <ul style={{ paddingLeft: "22px" }}>
                                <li>Systems Engineering</li>
                                <li>Game Design</li>
                                <li>User Experience</li>
                                <li>Prototyping</li>
                                <li>Scripting</li>
                                <li>Playtesting</li>
                            </ul>
                        </h3>
                    </div>
                    <div className="skills-container" ref={addToRefs}>
                        <h3 style={{fontWeight: "500"}}>Production</h3>
                        <h3>
                            <ul style={{ paddingLeft: "22px" }}>
                                <li>Agile Development</li>
                                <li>Scrum Development</li>
                                <li>Full Stack Development</li>
                                <li>Feedback Loops</li>
                                <li>Team Leadership</li>
                            </ul>
                        </h3>
                    </div>
                </div>
            </div>


            <div className="skill-title-area">
                <div className="title-container">
                    <div className="section-title" ref={addToRefs}>Software and Tools</div>
                </div>
            </div>


            <div className="skills-area">
                <div className="skills-flexbox">
                    <div className="skills-container" ref={addToRefs}>
                        <h3 style={{fontWeight: "500"}}>Game Engines</h3>
                        <h3>
                            <ul style={{ paddingLeft: "22px" }}>
                                <li>Unity 2D & 3D</li>
                                <li>Unreal Engine 5</li>
                                <li>OpenGL</li>
                                <li>ThreeJS</li>
                            </ul>
                        </h3>

                    </div>
                    <div className="skills-container" ref={addToRefs}>
                        <h3 style={{fontWeight: "500"}}>Source Control</h3>
                        <h3>
                            <ul style={{ paddingLeft: "22px" }}>
                                <li>Git</li>
                                <li>Perforce</li>
                                <li>SVN</li>
                            </ul>
                        </h3>
                    </div>
                    <div className="skills-container" ref={addToRefs}>
                        <h3 style={{fontWeight: "500"}}>Tracking</h3>
                        <h3>
                            <ul style={{ paddingLeft: "22px" }}>
                                <li>Figma</li>
                                <li>Jira</li>
                                <li>Milanote</li>
                                <li>Sheets</li>
                                <li>Trello</li>
                            </ul>
                        </h3>
                    </div>
                    <div className="skills-container" ref={addToRefs}>
                        <h3 style={{fontWeight: "500"}}>Communication</h3>
                        <h3>
                            <ul style={{ paddingLeft: "22px" }}>
                                <li>Discord</li>
                                <li>Teams</li>
                                <li>​Skype</li>
                                <li>Outlook</li>
                            </ul>
                        </h3>
                    </div>
                    <div className="skills-container" ref={addToRefs}>
                        <h3 style={{fontWeight: "500"}}>​Productivity</h3>
                        <h3>
                            <ul style={{ paddingLeft: "22px" }}>
                                <li>Asprite</li>
                                <li>AutoCAD</li>
                                <li>Blender</li>
                                <li>Photoshop</li>
                                <li>Illustrator</li>
                            </ul>
                        </h3>
                    </div>
                    <div className="skills-container" ref={addToRefs}>
                        <h3 style={{fontWeight: "500"}}>IDE</h3>
                        <h3>
                            <ul style={{ paddingLeft: "22px" }}>
                                <li>Visual studio</li>
                                <li>VSCode</li>
                                <li>CLion</li>
                            </ul>
                        </h3>
                    </div>
                </div>
            </div>

            {/*_____________________FOOTER.CSS_____________________*/}
            <div id="footer-area" >
                <h1 className="feet" ref={addToRefs}>Next Step? Let's Talk!</h1>
                <h3 className="feet" ref={addToRefs}>I'm currently looking for work. If you think I'd be a good addition to your team, check out my LinkedIn or send me an email!</h3>
                <div id="icon-container" ref={addToRefs}>
                    <a id="linkedin-icon" href="https://www.linkedin.com/in/chai-lin/" target="_blank"></a>
                    <a id="email-icon" href="chai.rex.lin@gmail.com" target="_blank"></a>
                    {/*<a id="insta-icon" href="https://www.instagram.com/_chai.lin_/" target="_blank"></a>*/}
                </div>
                <h2 ref={addToRefs}>Website Designed and Built by Chai Lin</h2>
            </div>
        </div>
    )
}

export default Intro;
import React, { Suspense, useRef, useEffect, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Loader, useGLTF, OrbitControls, PerspectiveCamera } from '@react-three/drei'
import { MathUtils } from "three";

function GLTFspinner(data) {
    const CameraRef = useRef()
    const [myFOV, setMyFOV] = useState(75);
    const planeAspectRatio = 11 / 9;

    // disable render
    const DisableRender = () => { useFrame(() => null, 1000); };

    // handle fov
    useEffect(() => {
        function newCameraFOV(aspect){
            const cameraHeight = Math.tan(MathUtils.degToRad(myFOV / 2));
            const ratio = aspect / planeAspectRatio;
            const newCameraHeight = cameraHeight / ratio;
            return MathUtils.radToDeg(Math.atan(newCameraHeight) * 2);
        }

        function setFOV() {
            const aspect = window.innerWidth / window.innerHeight;
            if (aspect > planeAspectRatio) {
                return myFOV;
            } else {
                return newCameraFOV(aspect);
            }
        }
        setMyFOV(setFOV);

        function resizeFOV() {
            const aspect = window.innerWidth / window.innerHeight;

            if (aspect > planeAspectRatio) {
                CameraRef.current.fov = myFOV;
            } else {
                CameraRef.current.fov = newCameraFOV(aspect);
            }
        }
        window.addEventListener('resize', resizeFOV);
        return () => window.removeEventListener('resize', resizeFOV);
    }, [])

    // load model
    function Model() {
        
        const { nodes, materials } = useGLTF(data.data[1]);
        return (
            <group rotation={[0, 0, 0]} position={[0, data.data[2], 0]} scale={2.2}>
                <group rotation={[Math.PI / 1, -Math.PI / 1, Math.PI / 1]}>
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.planet.geometry}
                        material={materials["color pallete"]}
                    />
                </group>
            </group>
        )
    }

    // add lights
    function Lights() {
        const spotLight = useRef()
        const pointLight = useRef()
        //useHelper(spotLight, SpotLightHelper, "teal")
        //useHelper(pointLight, PointLightHelper, 0.5, "hotpink")

        return (
            <>
                <pointLight intensity={1} position={[-10, -25, -24]} ref={pointLight} />
                <spotLight
                    castShadow
                    intensity={2.25}
                    angle={0.2}
                    penumbra={1}
                    position={[-25, 20, -15]}
                    shadow-mapSize={[1024, 1024]}
                    shadow-bias={-0.0001}
                    ref={spotLight}
                />
            </>
        )
    }

    // anti null
    if (!data) { return (<div />) };
    return (
        <>
            <Canvas dpr={[.5, .5]} linear shadows resize={{ debounce: 0 }}>
                {!data.data[0] && <DisableRender />}
                <fog attach="fog" args={['#272730', 16, 0]} />
                <ambientLight intensity={0.75} />
                <PerspectiveCamera makeDefault position={[0, 0, 30]} fov={myFOV} ref={CameraRef}>
                    <Lights />
                </PerspectiveCamera>
                <Suspense fallback={null}>
                    <Model/>
                </Suspense>
                <OrbitControls
                    autoRotate={true}
                    autoRotateSpeed={2}
                    enablePan={false}
                    enableZoom={false}
                    maxPolarAngle={Math.PI / 2}
                    minPolarAngle={Math.PI / 2}
                />
                {/*<primitive object={new THREE.AxesHelper(20)} />*/}
            </Canvas>

            <Loader />
        </>
    )
}

export default GLTFspinner;
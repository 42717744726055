import React, { useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Stars } from '@react-three/drei'
import "./Stars2.css"


function RotateStars() {
    const starRef = useRef()
    useFrame(() => {
        starRef.current.rotation.y += 0.0001;
        starRef.current.rotation.x += 0.0001;
    })

    return (
        <Stars radius={150} depth={50} count={5000} factor={10} ref={starRef}/>
    )
}


export default function Stars2() {
    return (
        <>
            <Canvas>
                <RotateStars/>
            </Canvas>
        </>
    )
}
import React, { useState, useRef } from "react";

import "./CyberpunkScene.css"

function CyberpunkScene() {
    //MOUSE MOVEMENT
    const [coords, setCoords] = useState({ x: window.innerWidth / 2 + 24, y: 0 });
    const handlePixelMouseMove = event => {
        setCoords({
            x: event.clientX - event.target.offsetLeft,
            y: event.clientY - event.target.offsetTop,
        });
    };

    //ANIMATE CHARACTER
    const characterRef = useRef()
    const [location, setLocation] = useState(0);
    const [animation, setAnimation] = useState("");
    const [direction, setDirection] = useState("");
    const moveSpeed = 0.01;
    const maxSpeed = 4;
    const minSpeed = 1;
    const pixelSize = (0.0015 * window.innerHeight);

    function animatingCharacters() {
        if (characterRef.current !== undefined) {
            const characterSize = 48;
            const waypoint = coords.x - (characterSize * pixelSize) / 2;


            function movement() {
                let distX = (waypoint - location) * moveSpeed;
                if (distX < minSpeed && distX > 0) {
                    setLocation(location + minSpeed);
                } else if (distX > -minSpeed && distX < 0) {
                    setLocation(location - minSpeed);
                }
                else if (distX > maxSpeed) {
                    setLocation(location + maxSpeed);
                } else if (distX < -maxSpeed) {
                    setLocation(location - maxSpeed);
                } else {
                    setLocation(location + distX);
                }
                characterRef.current.style.left = location + 'px';

            }

            if (location > waypoint + pixelSize * 16) {
                movement();
                setAnimation("running");
                setDirection("left")
            } else if (location < waypoint - pixelSize * 16) {
                movement();
                setAnimation("running");
                setDirection("right")
            } else {
                setAnimation("idle");
            }
        }
    }

    // Clouds
    const clouds1 = useRef();
    const clouds2 = useRef();
    const clouds3 = useRef();
    const clouds4 = useRef();
    const [cloudsLocation1, setCloudLocation1] = useState(0);
    const [cloudsLocation2, setCloudLocation2] = useState(0);
    const [cloudsLocation3, setCloudLocation3] = useState(0);
    const [cloudsLocation4, setCloudLocation4] = useState(0);

    function animatingClouds() {
        if (clouds1.current === undefined) { return }
        if (clouds2.current === undefined) { return }
        if (clouds3.current === undefined) { return }
        if (clouds4.current === undefined) { return }

        if (cloudsLocation1 < -640 * pixelSize) { setCloudLocation1(-3); }
        else { setCloudLocation1(cloudsLocation1 - 1); };
        clouds1.current.style.left = cloudsLocation1 + 'px';

        if (cloudsLocation2 < -640 * pixelSize) { setCloudLocation2(-1.5); }
        else { setCloudLocation2(cloudsLocation2 - .5); };
        clouds2.current.style.left = cloudsLocation2 + 'px';

        if (cloudsLocation3 < -640 * pixelSize) { setCloudLocation3(-.75); }
        else { setCloudLocation3(cloudsLocation3 - .25); };
        clouds3.current.style.left = cloudsLocation3 + 'px';

        if (cloudsLocation4 < -640 * pixelSize) { setCloudLocation4(-.3); }
        else { setCloudLocation4(cloudsLocation4 - .1); };
        clouds4.current.style.left = cloudsLocation4 + 'px';
    }

    // Trains
    const train1 = useRef();
    const [trainLocation1, setTrainLocation1] = useState(0);

    function animatingTrain() {
        if (train1.current === undefined) { return }

        if (trainLocation1 > window.innerWidth + 3000) { setTrainLocation1(-680 * pixelSize); }
        else { setTrainLocation1(trainLocation1 + 10); };
        train1.current.style.left = trainLocation1 + 'px';
    }



    // Update function
    requestAnimationFrame(function Update() {
        animatingCharacters();
        animatingClouds();
        animatingTrain();

    });

    return (
        <div id="pixel-scene">

            <div id="cloud-transition-2">
                <div id="clouds4" ref={clouds4}></div>
                <div id="clouds3" ref={clouds3}></div>
                <div id="clouds2" ref={clouds2}></div>
                <div id="clouds1" ref={clouds1}></div>
            </div>

            <div id="city-scene"></div>
            
            <div id="train1" ref={train1}></div>

            {/*<div id="coord-display">X:{coords.x} Y:{coords.y}</div>*/}

            <div id="character" ref={characterRef}>
                <img src="/assests/character-spritesheet.png" alt="" className={`character-spritesheet ${animation} ${direction}`} />
            </div>


            <div id="pixel-mouse-area" onMouseMove={handlePixelMouseMove}></div>
        </div>
    )
}

export default CyberpunkScene;